<template>
  <div class="page page-faq">
    <div class="page-faq__container">
      <ul class="faq">
        <li class="faq__item" v-for="(faq, i) in faqs" :key="i">
          <button type="button" class="faq__header" @click="toggleFaq(i)">
            <span>{{ faq.title }}</span>
            <IconComponent category="default" :name="activeFaq === i ? 'minus' : 'plus'" />
          </button>
          <div class="faq__body" v-if="activeFaq === i">{{ faq.text }}</div>
        </li>
      </ul>
      <ul class="page-faq__contacts">
        <li>
          <span>Техническая поддержка</span>
          <a href="mailto:dozalab@yandex.ru">dozalab@yandex.ru</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "UserFaqPage",
  data() {
    return {
      activeFaq: null,
      faqs: [
        {
          title: "На каких платформах доступно приложение?",
          text:
            "Приложение доступно на iOS и Android. Вы можете найти нас в App Store, Google Play.",
        },
        {
          title: 'Чем приложение "Туда Сюда" отличается от аналогов?',
          text: "В отличии от схожих приложений, также будут применены элементы геймификации, что сделает путешествие пользователей более увлекательными.",
        },
        {
          title: 'Куда обращаться, если у меня возникли сложности, ошибки, или пожелания по улучшению?',
          text: "В таком случае, вы можете перейти в кабинет, и найти раздел \"Обратная связь\". При нажатии на на него, вас перенесет на чат со Службой поддержки в удобный и привычный мессенджер Telegram.",
        },
        {
          title: 'Будет ли расширение функционала?',
          text: "Наша команда каждый день проделывает огромную работу, для того чтобы сделать ваши путешествия максимально интересными",
        },
      ],
    };
  },
  methods: {
    toggleFaq(i) {
      this.activeFaq = this.activeFaq === i ? null : i;
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/faq.styl"
.page-faq {
  padding 0 100px
  display grid
  +below(1310px) {
    padding 0 40px
  }
  +below(768px) {
    padding 0 20px
    grid-gap 40px
  }
  +below(560px) {
    padding 0 16px
  }

  &__container {
    display grid
    grid-template-columns 3fr 2fr
    grid-gap 80px
    align-items: flex-start;
    +below(1024px) {
      grid-template-columns 1fr
      grid-gap 40px
    }
  }

  &__contacts {
    display grid
    align-items flex-start
    gap 20px
    list-style none
    padding 0
    margin 0

    li {
      display grid
      gap 5px
    }

    span {
      font-weight: 700;
      font-size: 1.375em;
      color: var(--main);
    }

    a {
      font-weight: 500;
      font-size: 1.125em;
      line-height: 145%;
      color: var(--main);
      text-decoration underline
    }
  }
}
</style>
